import React from "react";
import { Container, Row, Col } from "shards-react";
import { connect } from "react-redux";
import { css, cx } from "@emotion/css";
import moment from "moment";

import PageTitle from "./../components/common/PageTitle";
import SmallStats from "./../components/common/SmallStats";
import UsersOverview from "./../components/blog/UsersOverview";
// import UsersByDevice from './../components/blog/UsersByDevice'
// import NewDraft from './../components/blog/NewDraft'
// import Discussions from './../components/blog/Discussions'
import TopReferrals from "./../components/common/TopReferrals";

import { getMonthlyStats, getDailyStats, getProcessingCounts, getShippingCounts } from "../redux/StatsActions";

class Dashboard extends React.Component {
    componentDidMount() {
        this.props.getMonthlyStats().then((res) => {
            console.log(res);
        });

        this.props.getDailyStats().then((res) => {
            console.log(res);
        });

        this.props.getProcessingCounts().then((res) => {
            console.log(res);
        });

        this.props.getShippingCounts().then((res) => {
            console.log(res);
        });
    }

    getPercentageDiff(newVal, orgVal) {
        let totalPerc = 0;

        const diff = parseFloat(newVal) - parseFloat(orgVal);
        if (orgVal !== 0 && diff !== 0) {
            totalPerc = (diff / orgVal) * 100;
        }

        return Math.round((totalPerc + Number.EPSILON) * 100) / 100;
    }

    render() {
        const { dailyStats, monthlyStats, processingCounts, shippingCounts } = this.props;

        let monthlyStatsData = [];
        let dailyStatsData = [];
        let shippingCountsData = [];

        if (monthlyStats) {
            monthlyStatsData = [
                {
                    label: "Neue Bestellungen",
                    value: monthlyStats.totalOrders,
                    percentage: "0%",
                    increase: true,
                    chartLabels: [null, null, null, null, null, null, null],
                    attrs: { md: "6", sm: "6" },
                    datasets: [
                        {
                            label: "Today",
                            fill: "start",
                            borderWidth: 1.5,
                            backgroundColor: "rgba(0, 184, 216, 0.1)",
                            borderColor: "rgb(0, 184, 216)",
                            // data: [1, 2, 1, 3, 5, 4, 7],
                            data: [],
                        },
                    ],
                },
                {
                    label: "Abgeschlossen",
                    value: monthlyStats.completedOrders,
                    percentage: "0%",
                    increase: true,
                    decrease: false,
                    chartLabels: [null, null, null, null, null, null, null],
                    attrs: { md: "4", sm: "6" },
                    datasets: [
                        {
                            label: "Today",
                            fill: "start",
                            borderWidth: 1.5,
                            backgroundColor: "rgba(23,198,113,0.1)",
                            borderColor: "rgb(23,198,113)",
                            // data: [2, 3, 3, 3, 4, 3, 3],
                            data: [],
                        },
                    ],
                },
                {
                    label: "Retouren",
                    value: monthlyStats.retoureOrders,
                    percentage: "0%",
                    increase: true,
                    chartLabels: [null, null, null, null, null, null, null],
                    attrs: { md: "6", sm: "6" },
                    datasets: [
                        {
                            label: "Today",
                            fill: "start",
                            borderWidth: 1.5,
                            backgroundColor: "rgba(255,180,0,0.1)",
                            borderColor: "rgb(255,180,0)",
                            // data: [1, 2, 3, 3, 3, 4, 4],
                            data: [],
                        },
                    ],
                },
            ];
        }

        if (dailyStats) {
            const totalOrdersPrecentage = this.getPercentageDiff(dailyStats.totalOrders, dailyStats.previousDay.prevTotalOrders);
            const completedOrdersPrecentage = this.getPercentageDiff(
                dailyStats.completedOrders,
                dailyStats.previousDay.prevCompletedOrders
            );
            const retoureOrdersPrecentage = this.getPercentageDiff(dailyStats.retoureOrders, dailyStats.previousDay.prevRetoureOrders);

            dailyStatsData = [
                {
                    label: "Neue Bestellungen",
                    value: dailyStats.totalOrders,
                    percentage: totalOrdersPrecentage + "% (" + dailyStats.previousDay.prevTotalOrders + ")",
                    increase: dailyStats.totalOrders >= dailyStats.previousDay.prevTotalOrders,
                    decrease: dailyStats.totalOrders < dailyStats.previousDay.prevTotalOrders,
                    chartLabels: [null, null, null, null, null, null, null],
                    attrs: { md: "6", sm: "6" },
                    datasets: [
                        {
                            label: "Today",
                            fill: "start",
                            borderWidth: 1.5,
                            backgroundColor: "rgba(0, 184, 216, 0.1)",
                            borderColor: "rgb(0, 184, 216)",
                            data: [],
                        },
                    ],
                },
                {
                    label: "Abgeschlossen",
                    value: dailyStats.completedOrders,
                    percentage: completedOrdersPrecentage + "% (" + dailyStats.previousDay.prevCompletedOrders + ")",
                    increase: dailyStats.completedOrders >= dailyStats.previousDay.prevCompletedOrders,
                    decrease: dailyStats.completedOrders < dailyStats.previousDay.prevCompletedOrders,
                    chartLabels: [null, null, null, null, null, null, null],
                    attrs: { md: "4", sm: "6" },
                    datasets: [
                        {
                            label: "Today",
                            fill: "start",
                            borderWidth: 1.5,
                            backgroundColor: "rgba(23,198,113,0.1)",
                            borderColor: "rgb(23,198,113)",
                            data: [],
                        },
                    ],
                },
                {
                    label: "Retouren",
                    value: dailyStats.retoureOrders,
                    percentage: retoureOrdersPrecentage + "% (" + dailyStats.previousDay.prevRetoureOrders + ")",
                    increase: dailyStats.retoureOrders >= dailyStats.previousDay.prevRetoureOrders,
                    decrease: dailyStats.retoureOrders < dailyStats.previousDay.prevRetoureOrders,
                    chartLabels: [null, null, null, null, null, null, null],
                    attrs: { md: "6", sm: "6" },
                    datasets: [
                        {
                            label: "Today",
                            fill: "start",
                            borderWidth: 1.5,
                            backgroundColor: "rgba(255,180,0,0.1)",
                            borderColor: "rgb(255,180,0)",
                            data: [],
                        },
                    ],
                },
            ];
        }

        if (shippingCounts.today) {
            shippingCounts.today.forEach((country) => {
                const prevCountry = shippingCounts.prev.find((p) => p.shortCode === country.shortCode);
                if (prevCountry) {
                    country.prev = prevCountry.count;
                    country.increase = country.count >= country.prev;
                    country.decrease = country.count > country.prev;
                }
            });

            shippingCountsData = shippingCounts.today.map((country) => {
                return {
                    label: country.shortCode,
                    value: country.count,
                    percentage: `${this.getPercentageDiff(country.count, country.prev)}% (${country.prev})`,
                    increase: country.increase,
                    decrease: country.decrease,
                    chartLabels: [null, null, null, null, null, null, null],
                    attrs: { md: "6", sm: "6" },
                    datasets: [
                        {
                            label: "Today",
                            fill: "start",
                            borderWidth: 1.5,
                            backgroundColor: "rgba(255,180,0,0.1)",
                            borderColor: "rgb(255,180,0)",
                            data: [],
                        },
                    ],
                };
            });
        }

        return (
            <Container fluid className="main-content-container px-4">
                {/* Page Header */}
                <Row noGutters className="page-header py-4">
                    <PageTitle title={`Monatsübersicht`} subtitle={`${moment().format("MMMM YYYY")}`} className="text-sm-left mb-3" />
                </Row>

                {/* Small Stats Blocks */}
                <Row>
                    {monthlyStatsData.map((stats, idx) => (
                        <Col className={cx("col-lg mb-4", removePercentage)} key={idx} {...stats.attrs}>
                            <SmallStats
                                id={`small-stats-${idx}`}
                                variation="1"
                                chartData={stats.datasets}
                                chartLabels={stats.chartLabels}
                                label={stats.label}
                                value={stats.value}
                                percentage={stats.percentage}
                                increase={stats.increase}
                                decrease={stats.decrease}
                            />
                        </Col>
                    ))}
                </Row>

                <Row noGutters className="page-header py-4">
                    <PageTitle title={`Tagesübersicht`} subtitle={`${moment().format("DD.MM.YYYY")}`} className="text-sm-left mb-3" />
                </Row>

                {/* Small Stats Blocks */}
                <Row>
                    {dailyStatsData.map((stats, idx) => (
                        <Col className="col-lg mb-4" key={idx} {...stats.attrs}>
                            <SmallStats
                                id={`small-stats-${idx}`}
                                variation="1"
                                chartData={stats.datasets}
                                chartLabels={stats.chartLabels}
                                label={stats.label}
                                value={stats.value}
                                percentage={stats.percentage}
                                increase={stats.increase}
                                decrease={stats.decrease}
                            />
                        </Col>
                    ))}
                </Row>

                <Row noGutters className="page-header py-4">
                    <PageTitle title={`Versandübersicht`} subtitle={`${moment().format("DD.MM.YYYY")}`} className="text-sm-left mb-3" />
                </Row>

                {/* Small Stats Blocks */}
                <Row>
                    {shippingCountsData.map((stats, idx) => (
                        <Col className="col-lg mb-4" key={idx} {...stats.attrs}>
                            <SmallStats
                                id={`small-stats-${idx}`}
                                variation="1"
                                chartData={stats.datasets}
                                chartLabels={stats.chartLabels}
                                label={stats.label}
                                value={stats.value}
                                percentage={stats.percentage}
                                increase={stats.increase}
                                decrease={stats.decrease}
                            />
                        </Col>
                    ))}
                </Row>

                <Row noGutters className="page-header py-4">
                    <PageTitle title="Shopübersicht" subtitle="" className="text-sm-left mb-3" />
                </Row>

                <Row>
                    {/* Users by Device */}
                    {/* <Col lg="9" md="6" sm="12" className="mb-4">
                        <UsersOverview title="Bestellungen" />
                    </Col> */}
                    {/* Top Referrals */}
                    <Col lg={{ size: 3, offset: 0 }} md="12" sm="12" className="mb-4">
                        <TopReferrals title="Bestellungen aktuell" referralData={processingCounts} />
                    </Col>
                </Row>

                <Row>
                    {/* Users Overview */}
                    {/*<Col lg="8" md="12" sm="12" className="mb-4">
        <UsersOverview />
      </Col>*/}

                    {/* Users by Device */}
                    {/*<Col lg="4" md="6" sm="12" className="mb-4">
        <UsersByDevice />
      </Col>*/}

                    {/* New Draft */}
                    {/*<Col lg="4" md="6" sm="12" className="mb-4">
        <NewDraft />
      </Col>*/}

                    {/* Discussions */}
                    {/*<Col lg="5" md="12" sm="12" className="mb-4">
        <Discussions />
      </Col>*/}
                </Row>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        monthlyStats: state.statsReducer.monthlyStats,
        dailyStats: state.statsReducer.dailyStats,
        processingCounts: state.statsReducer.processingCounts,
        shippingCounts: state.statsReducer.shippingCounts,
    };
};

const mapActionsToProps = { getMonthlyStats, getDailyStats, getProcessingCounts, getShippingCounts };

export default connect(mapStateToProps, mapActionsToProps)(Dashboard);

const removePercentage = css`
    .stats-small__percentage {
        display: none !important;
    }
`;
