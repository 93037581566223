import React from "react";
import { Field, reduxForm } from "redux-form";
import { Form, FormInput, FormGroup, Button, ListGroupItem } from "shards-react";

const renderField = (field) => (
    <FormInput
        component="input"
        type="text"
        // onChange={(ev) => this.setState({ username: ev.target.value })}
        autoComplete="new-password"
        // ref={(input) => (this.state.usernameField = input)}
        {...field.input}
    />
);

const renderPassword = (field) => (
    <FormInput
        component="input"
        type="password"
        // onChange={(ev) => this.setState({ username: ev.target.value })}
        autoComplete="new-password"
        // ref={(input) => (this.state.usernameField = input)}
        {...field.input}
    />
);

class LoginForm extends React.Component {
    render() {
        return (
            <Form onSubmit={this.props.handleSubmit}>
                <ListGroupItem className="px-3">
                    <FormGroup>
                        <label htmlFor="loginUsername">Username</label>
                        <Field name="username" component={renderField} placeholder="Username" />
                        <label htmlFor="loginPassword">Password</label>
                        <Field name="password" component={renderPassword} placeholder="Password" />
                    </FormGroup>
                </ListGroupItem>
                <ListGroupItem className="px-3 pt-0">
                    <Button type="submit">Login</Button>
                </ListGroupItem>
            </Form>
        );
    }
}

export default reduxForm({
    // a unique name for the form
    form: "login",
})(LoginForm);
