import React from "react";
import PropTypes from "prop-types";
import { Navbar, NavbarBrand } from "shards-react";

import { Dispatcher, Constants } from "../../../flux";

class SidebarMainNavbar extends React.Component {
    constructor(props) {
        super(props);

        this.handleToggleSidebar = this.handleToggleSidebar.bind(this);
    }

    handleToggleSidebar() {
        Dispatcher.dispatch({
            actionType: Constants.TOGGLE_SIDEBAR,
        });
    }

    render() {
        let { hideLogoText } = this.props;
        hideLogoText = false;
        return (
            <div className="main-navbar">
                <Navbar className="align-items-stretch bg-white flex-md-nowrap border-bottom p-0" type="light">
                    <NavbarBrand className="w-100 mr-0 p-0" href="#" style={{ lineHeight: "25px" }}>
                        {/* <img
                            // id="main-logo"
                            // className="d-inline-block align-top mr-1"
                            style={{ width: "100%" }}
                            src={require("../../../images/logo.jpg")}
                            alt="Logo"
                        /> */}
                        <div
                            style={{
                                width: "100%",
                                height: "100%",
                                background: `url(${require("../../../images/logo_white_60.jpg")})`,
                                backgroundSize: "contain",
                                backgroundRepeat: "no-repeat",
                                backgroundColor: "white",
                            }}
                        ></div>
                        {/* <div className="d-table m-auto">
                            <img
                                id="main-logo"
                                // className="d-inline-block align-top mr-1"
                                style={{ maxWidth: "25px" }}
                                src={require("../../../images/logo.jpg")}
                                alt="Shards Dashboard"
                            />
                            {!hideLogoText && <span className="d-none d-md-inline ml-1">motherboard</span>}
                        </div> */}
                    </NavbarBrand>
                    {/* eslint-disable-next-line */}
                    <a className="toggle-sidebar d-sm-inline d-md-none d-lg-none" onClick={this.handleToggleSidebar}>
                        <i className="material-icons">&#xE5C4;</i>
                    </a>
                </Navbar>
            </div>
        );
    }
}

SidebarMainNavbar.propTypes = {
    /**
     * Whether to hide the logo text, or not.
     */
    hideLogoText: PropTypes.bool,
};

SidebarMainNavbar.defaultProps = {
    hideLogoText: false,
};

export default SidebarMainNavbar;
