import fetch from "isomorphic-fetch";
// import { hashHistory } from 'react-router'
// import store from './store'
// import { SHOW_SERVER_ERROR } from '../modules/App/AppActions.js'

function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(";");
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

// export const API_URL = `${process.env.REACT_APP_API_ENDPOINT}:${process.env.REACT_APP_API_PORT}/api`
export const API_URL = `${process.env.REACT_APP_API_ENDPOINT}/api`;

console.info("API-Url: " + API_URL);
console.log(`WEBSOCKET: ${process.env.REACT_APP_WEBSOCKET_ENDPOINT}`);

export default function callApi(endpoint, method = "get", body) {
    return fetch(`${API_URL}/${endpoint}`, {
        headers: {
            "content-type": "application/json",
            Authorization: "JWT " + getCookie("token"),
        },
        method,
        body: JSON.stringify(body),
        timeout: 10000000,
        // credentials: 'include'
    })
        .then((response) => {
            if (response.status === 401) {
                // hashHistory.push('/login')
                return Promise.reject({
                    status: response.status,
                    statusText: response.statusText,
                });
            } else if (response.status === 403) {
                response.json().then(
                    (json) => {
                        console.log(json);
                        // store.dispatch({
                        // 	type: SHOW_SERVER_ERROR,
                        // 	payload: { message: json.error },
                        // })
                    },
                    (err) => {
                        console.log(err);
                    }
                );
                return Promise.reject();
            }
            return response.json().then((json) => ({ json, response }));
        })
        .then(({ json, response }) => {
            if (!response.ok) {
                console.log("🧨 SERVER ERROR:");
                console.log(json);
                if (json.error) {
                    // const state = store.getState()
                    // let selectedOrder
                    // if (state && state.ordersReducer && state.ordersReducer.selectedOrder) {
                    // 	selectedOrder = state.ordersReducer.selectedOrder._id
                    // }
                    // store.dispatch({
                    // 	type: SHOW_SERVER_ERROR,
                    // 	payload: { message: json.error, context: selectedOrder, dpdLabelError: json.dpdLabelError },
                    // })
                }
                return Promise.reject(json);
            }

            return json;
        });
}
