import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout, EmptyLayout } from "./layouts";

// Route Views
import Dashboard from "./views/Dashboard";
import UserProfileLite from "./views/UserProfileLite";
import Errors from "./views/Errors";
import StorageCosts from "./views/StorageCosts";
import StorageCostsDetails from "./views/StorageCostsDetails";
import Login from "./views/Login";

import PrivateRoute from "./views/PrivateRoute";

export default [
    {
        path: "/",
        exact: true,
        layout: DefaultLayout,
        component: () => <Redirect to="/dashboard" />,
    },
    {
        path: "/dashboard",
        layout: DefaultLayout,
        component: () => <PrivateRoute component={Dashboard} />,
    },
    {
        path: "/users",
        layout: DefaultLayout,
        component: () => <PrivateRoute component={UserProfileLite} />,
    },
    {
        path: "/storage-costs",
        layout: DefaultLayout,
        exact: true,
        component: () => <PrivateRoute component={StorageCosts} />,
    },
    {
        path: "/storage-costs/:shopId",
        layout: DefaultLayout,
        exact: true,
        component: () => <PrivateRoute component={StorageCostsDetails} />,
    },
    {
        path: "/errors",
        layout: DefaultLayout,
        component: Errors,
    },
    {
        path: "/login",
        layout: EmptyLayout,
        component: Login,
    },
];
