/**
 * Main store function
 */
import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import rootReducer from './reducers'

export function configureStore(initialState = {}) {
	// Middleware and store enhancers
	const enhancers = [applyMiddleware(thunk)]

	let composeEnhancers = compose

	// if (process.env.CLIENT && process.env.NODE_ENV === "development") {
	// Enable DevTools only when rendering on client and during development.
	// @INFO: We are always on client now
	composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
	// }

	const store = createStore(rootReducer, initialState, composeEnhancers(...enhancers))

	// For hot reloading reducers
	if (module.hot) {
		// Enable Webpack hot module replacement for reducers
		module.hot.accept('./reducers', () => {
			const nextReducer = require('./reducers').default // eslint-disable-line global-require
			store.replaceReducer(nextReducer)
		})
	}

	return store
}
