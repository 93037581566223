/**
 * Root Reducer
 *
 * https://github.com/bradtraversy/mern_shopping_list
 */
import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'

// Import Reducers
import authReducer from './redux/AuthReducer'
import statsReducer from './redux/StatsReducer'

// Combine all reducers into one root reducer
export default combineReducers({
	authReducer,
	statsReducer,
	form: formReducer,
})
