import React from 'react'
import { Container, Row, Col } from 'shards-react'

const EmptyLayout = ({ children }) => (
  <Container fluid>
    <Row>
      <Col className="main-content p-0" lg={{ size: 8, offset: 2 }} md={{ size: 6, offset: 3 }} sm="12" tag="main">
        {children}
      </Col>
    </Row>
  </Container>
)

export default EmptyLayout
