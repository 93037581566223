import {
    READ_MONTHLY_STATS,
    READ_DAILY_STATS,
    READ_PROCESSING_COUNTS,
    READ_CURRENT_STORAGES,
    READ_ALL_SHOPS,
    READ_STORAGES_BY_SHOPID,
    READ_SHIPPING_COUNTS,
} from "./StatsActions";

const initialState = {
    monthlyStats: null,
    dailyStats: null,
    processingCounts: [],
    currentStorages: [],
    storagesByShopId: {},
    shops: [],
    shippingCounts: [],
};

const StatsReducer = (state = initialState, action) => {
    switch (action.type) {
        case READ_MONTHLY_STATS:
            return {
                ...state,
                monthlyStats: action.payload,
            };
        case READ_DAILY_STATS:
            return {
                ...state,
                dailyStats: action.payload,
            };
        case READ_PROCESSING_COUNTS:
            return {
                ...state,
                processingCounts: action.payload,
            };
        case READ_CURRENT_STORAGES:
            return {
                ...state,

                currentStorages: action.payload,
            };
        case READ_STORAGES_BY_SHOPID:
            const array = { ...state.storagesByShopId };
            array[action.payload.shopId] = action.payload.storages;
            return {
                ...state,
                storagesByShopId: array,
            };
        case READ_ALL_SHOPS:
            return {
                ...state,
                shops: action.payload ? action.payload.shops : [],
            };
        case READ_SHIPPING_COUNTS:
            return {
                ...state,
                shippingCounts: action.payload.shippingCounts,
            };
        default:
            return state;
    }
};

export default StatsReducer;
