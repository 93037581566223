import React from "react";
import { Container, Row, Col, Card, CardHeader, ListGroup } from "shards-react";
import { withCookies, Cookies } from "react-cookie";
import { instanceOf } from "prop-types";
import { connect } from "react-redux";

import { login, getUsers } from "../redux/AuthActions";

import LoginForm from "./forms/LoginForm";

class Login extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            users: [],
            // selectedUser: null,
            username: "",
            password: "",
            usernameField: null,
            passwordField: null,
            loading: true,
            loginError: false,
            requestLogin: false,
        };

        this.loginUser = this.loginUser.bind(this);
        // this.selectedUser = this.selectedUser.bind(this)
        // this.handleKeyDown = this.handleKeyDown.bind(this)
        // this.closeModal = this.closeModal.bind(this)
    }

    componentDidMount() {
        this.setState({ loading: true });
        this.props.getUsers().then(
            (res) => {
                const { users } = res.payload;
                this.setState({ loading: false, users });
            },
            (err) => {
                console.log("Error");
                this.setState({ loading: false });
            }
        );

        // setTimeout(() => {
        // 	console.log('focus')
        // 	console.log(this.state.usernameField)
        // 	this.state.usernameField.focus()
        // }, 2000)
    }

    componentWillUnmount() {
        console.log("will unmount");
    }

    loginUser(values) {
        const { cookies } = this.props;

        this.setState({ requestLogin: true });

        const username = values.username;
        const password = values.password;

        console.log("Creds: " + username + " " + password);

        if (username && password) {
            this.props
                .login({
                    username: username,
                    password: password,
                })
                .then((res, err) => {
                    console.log(res);
                    // this.setState({ requestLogin: false })

                    const { user, token } = res.payload;

                    if (user && token) {
                        cookies.set("user", user, { path: "/" });
                        cookies.set("token", token, { path: "/" });
                        this.props.history.push("/");
                    }
                })
                .catch((err) => {
                    console.info("Login failed");
                    // this.setState({ loginError: true, requestLogin: false })
                    // this.state.usernameField.focus()
                });
        }
    }

    // selectedUser(username) {
    // 	this.setState({ selectedUser: username })
    // 	// focus input
    // 	const that = this
    // 	setTimeout(() => {
    // 		that.pwInput.focus()
    // 	}, 60)
    // }

    // handleKeyDown(e) {
    // 	if (e.key === 'Enter') {
    // 		this.loginUser(e)
    // 	} else {
    // 		if (this.state.loginError) this.setState({ loginError: false })
    // 	}
    // }

    render() {
        return (
            <Container fluid className="main-content-container px-4 pb-4">
                <div className="error11">
                    <div className="error__content11">
                        <Row form>
                            <Col md="12" className="form-group">
                                <Card small>
                                    <CardHeader className="border-bottom">
                                        <h6 className="m-0">Login</h6>
                                    </CardHeader>

                                    <ListGroup flush>
                                        <LoginForm onSubmit={this.loginUser} />
                                    </ListGroup>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Container>
        );
    }
}

Login.propTypes = {
    cookies: instanceOf(Cookies).isRequired,
};

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        // noConnection: state.app.noConnection,
        // serverError: state.app.serverError,
    };
};

export default connect(mapStateToProps, {
    login,
    getUsers,
})(withCookies(Login));
