import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Collapse, NavItem, NavLink } from 'shards-react'
import { withCookies, Cookies } from 'react-cookie'
import { instanceOf } from 'prop-types'

class UserActions extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			visible: false,
		}

		this.toggleUserActions = this.toggleUserActions.bind(this)
		this.logout = this.logout.bind(this)
	}

	toggleUserActions() {
		this.setState({
			visible: !this.state.visible,
		})
	}

	logout() {
		const { cookies } = this.props

		cookies.remove('user', { path: '/' })
		cookies.remove('token', { path: '/' })
		cookies.remove('device', { path: '/' })
		cookies.remove('deviceName', { path: '/' })
	}

	render() {
		const { user } = this.props

		return (
			<NavItem tag={Dropdown} caret toggle={this.toggleUserActions}>
				<DropdownToggle caret tag={NavLink} className="text-nowrap px-3">
					<img className="user-avatar rounded-circle mr-2" src={user && user.image} alt="User Avatar" />{' '}
					<span className="d-none d-md-inline-block">{user && user.username}</span>
				</DropdownToggle>
				<Collapse tag={DropdownMenu} right small open={this.state.visible}>
					<DropdownItem tag={Link} to="users">
						<i className="material-icons">&#xE7FD;</i> Profile
					</DropdownItem>
					<DropdownItem divider />
					<DropdownItem onClick={this.logout} className="text-danger">
						<i className="material-icons text-danger">&#xE879;</i> Logout
					</DropdownItem>
				</Collapse>
			</NavItem>
		)
	}
}

UserActions.propTypes = {
	cookies: instanceOf(Cookies).isRequired,
}

const mapStateToProps = (state) => {
	return {
		user: state.authReducer.user,
	}
}

export default connect(mapStateToProps, null)(withCookies(UserActions))
