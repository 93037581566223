import callApi from '../utils/apiCaller'

// export constants
export const LOGIN = 'LOGIN'
export const LOGIN_FAILED = 'LOGIN_FAILED'
export const GET_USERS = 'GET_USERS'
export const GET_DEVICES = 'GET_DEVICES'
export const LOCK_DEVICE = 'LOCK_DEVICE'
export const UNLOCK_DEVICE = 'UNLOCK_DEVICE'
export const RESTORE_DEVICE = 'RESTORE_DEVICE'

export const login = ({ username, password }) => {
	return (dispatch) => {
		return callApi('auth/login', 'post', { username, password }).then(
			(result) => {
				return dispatch({
					type: LOGIN,
					payload: result,
				})
			},
			(error) => {
				return dispatch({
					type: LOGIN_FAILED,
				})
			}
		)
	}
}

export const getUsers = () => {
	return (dispatch) => {
		return callApi('auth/users').then((users) => {
			return dispatch({
				type: GET_USERS,
				payload: users,
			})
		})
	}
}

export const getDevices = () => {
	return (dispatch) => {
		return callApi('devices').then((devices) => {
			return dispatch({
				type: GET_DEVICES,
				payload: devices,
			})
		})
	}
}

export const lockDevice = (device) => {
	return (dispatch) => {
		return callApi('devices/' + device + '/lock', 'POST').then(({ locked }) => {
			return dispatch({
				type: LOCK_DEVICE,
				payload: locked,
			})
		})
	}
}

export const unlockDevice = (device) => {
	return (dispatch) => {
		return callApi('devices/' + device + '/unlock', 'POST').then(({ unlocked }) => {
			return dispatch({
				type: UNLOCK_DEVICE,
				payload: unlocked,
			})
		})
	}
}
