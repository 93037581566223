import dayjs from "dayjs";
import callApi from "../utils/apiCaller";

// export constants
export const READ_MONTHLY_STATS = "READ_MONTHLY_STATS";
export const READ_DAILY_STATS = "READ_DAILY_STATS";
export const READ_PROCESSING_COUNTS = "READ_PROCESSING_COUNTS";
export const READ_CURRENT_STORAGES = "READ_CURRENT_STORAGES";
export const READ_ALL_SHOPS = "READ_ALL_SHOPS";
export const READ_STORAGES_BY_SHOPID = "READ_STORAGES_BY_SHOPID";
export const READ_SHIPPING_COUNTS = "READ_SHIPPING_COUNTS";
export const GET_CSV = "GET_CSV";
export const GET_XLSX_FOR_ALL_SHOPS = "GET_XLSX_FOR_ALL_SHOPS";

export const getMonthlyStats = () => {
    return (dispatch) => {
        return callApi("stats/monthly", "get").then((result) => {
            return dispatch({
                type: READ_MONTHLY_STATS,
                payload: result,
            });
        });
    };
};

export const getDailyStats = () => {
    return (dispatch) => {
        return callApi("stats/daily", "get").then((result) => {
            return dispatch({
                type: READ_DAILY_STATS,
                payload: result,
            });
        });
    };
};

export const getProcessingCounts = () => {
    return (dispatch) => {
        return callApi("stats/processing-counts", "get").then((result) => {
            return dispatch({
                type: READ_PROCESSING_COUNTS,
                payload: result,
            });
        });
    };
};

export const getCurrentStorages = (year, month) => {
    if (!year) year = dayjs().format("YYYY");
    if (!month) month = dayjs().format("MM");
    return (dispatch) => {
        return callApi("stats/current-storages", "post", { year, month }).then((result) => {
            return dispatch({
                type: READ_CURRENT_STORAGES,
                payload: result,
            });
        });
    };
};

export const getCSV = (shopId, year, month) => {
    if (!year) year = dayjs().format("YYYY");
    if (!month) month = dayjs().format("MM");
    return (dispatch) => {
        return callApi("stats/current-storages/csv", "post", { shopId, year, month }).then((result) => {
            return dispatch({
                type: GET_CSV,
                payload: result,
            });
        });
    };
};

export const getXLSXForAllShops = (year, month) => {
    if (!year) year = dayjs().format("YYYY");
    if (!month) month = dayjs().format("MM");
    return (dispatch) => {
        return callApi("stats/all-current-storages/xlsx", "post", { year, month }).then((result) => {
            return dispatch({
                type: GET_XLSX_FOR_ALL_SHOPS,
                payload: result,
            });
        });
    };
};

export const getStoragesByShopId = (shopId) => {
    return (dispatch) => {
        return callApi("stats/storages/" + shopId, "get").then((result) => {
            return dispatch({
                type: READ_STORAGES_BY_SHOPID,
                payload: result,
            });
        });
    };
};

export const getAllShops = () => {
    return (dispatch) => {
        return callApi("shops", "get").then((result) => {
            return dispatch({
                type: READ_ALL_SHOPS,
                payload: result,
            });
        });
    };
};

export const getShippingCounts = () => {
    return (dispatch) => {
        return callApi("stats/shipping-counts", "get").then((result) => {
            return dispatch({
                type: READ_SHIPPING_COUNTS,
                payload: result,
            });
        });
    };
};
