import React from "react";
import { Redirect, Route, withRouter } from "react-router-dom";
import { withCookies, Cookies } from "react-cookie";
import { instanceOf } from "prop-types";

class PrivateRoute extends React.Component {
    render() {
        let token = this.props.cookies.get("token");
        let pathname = this.props.location.pathname;

        if (!token && pathname !== "/login") {
            localStorage.setItem("redirectTo", pathname);
        }

        return <Route {...this.props.rest} render={(props) => (token ? <this.props.component {...props} /> : <Redirect to="/login" />)} />;
    }
}

PrivateRoute.propTypes = {
    cookies: instanceOf(Cookies).isRequired,
};

export default withRouter(withCookies(PrivateRoute));
