import { LOGIN, LOGIN_FAILED, GET_USERS, GET_DEVICES, LOCK_DEVICE, UNLOCK_DEVICE, RESTORE_DEVICE } from './AuthActions'

const initialState = {
	user: null,
	token: null,
	users: [],
	device: null,
	deviceName: null,
	printerUrl: null,
	devices: [],
}

const AuthReducer = (state = initialState, action) => {
	switch (action.type) {
		case LOGIN:
			return {
				...state,
				user: action.payload.user,
				token: action.payload.token,
			}
		case LOGIN_FAILED:
			return {
				...state,
				user: null,
				token: null,
			}
		case GET_USERS:
			return {
				...state,
				users: action.payload.users,
			}
		case GET_DEVICES:
			return {
				...state,
				devices: action.payload.devices,
			}
		case LOCK_DEVICE:
			return {
				...state,
				device: action.payload.device,
				deviceName: action.payload.deviceName,
				printerUrl: action.payload.printerUrl,
				devices: state.devices.map((d) => {
					if (action.payload && d.device === action.payload.device) {
						return action.payload.device
					} else {
						return d
					}
				}),
			}
		case RESTORE_DEVICE:
			return {
				...state,
				device: action.payload.device,
				deviceName: action.payload.deviceName,
				printerUrl: action.payload.printerUrl,
			}
		case UNLOCK_DEVICE:
			return {
				...state,
				device: null,
				deviceName: null,
				printerUrl: null,
				devices: state.devices.map((d) => {
					if (action.payload && d.device === action.payload.device) {
						return action.payload.device
					} else {
						return d
					}
				}),
			}
		default:
			return state
	}
}

export default AuthReducer
