import React from "react";
import { Container, Row, Col, Card, CardHeader, Button, ListGroup, ListGroupItem } from "shards-react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import PageTitle from "../components/common/PageTitle";
import { getCurrentStorages, getAllShops, getCSV, getXLSXForAllShops } from "../redux/StatsActions";

import dayjs from "dayjs";

/** Download contents as a file
 * Source: https://stackoverflow.com/questions/14964035/how-to-export-javascript-array-info-to-csv-on-client-side
 */
function downloadBlob(content, filename, contentType) {
    // Create a blob
    var blob = new Blob([content], { type: contentType });
    var url = URL.createObjectURL(blob);

    // Create a link to download it
    var pom = document.createElement("a");
    pom.href = url;
    pom.setAttribute("download", filename);
    pom.click();
}

function downloadXlsxBlob(xlsxBinary, filename) {
    const buf = new ArrayBuffer(xlsxBinary.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < xlsxBinary.length; i++) {
        view[i] = xlsxBinary.charCodeAt(i) & 0xff;
    }
    // return buf

    // Create a blob
    var blob = new Blob([buf], { type: "application/octet-stream" });
    var url = URL.createObjectURL(blob);

    // Create a link to download it
    var pom = document.createElement("a");
    pom.href = url;
    pom.setAttribute("download", filename);
    pom.click();
}

class StorageCosts extends React.Component {
    constructor(props) {
        super(props);
        console.log(this.props);

        // set dayjs locale
        require("dayjs/locale/de");
        dayjs.locale("de");

        this.state = {
            selectedDate: `${dayjs().format("YYYY")}-${dayjs().format("MM")}`,
            creatingCSV: false,
        };

        this.getShopData = this.getShopData.bind(this);
        this.selectMonth = this.selectMonth.bind(this);
        this.getCSV = this.getCSV.bind(this);
        this.getXLSX = this.getXLSX.bind(this);
    }

    componentDidMount() {
        this.props.getCurrentStorages().then((res) => {
            console.log("storages:");
            console.log(res);
        });
        this.props.getAllShops().then((res) => {
            console.log("shops:");
            console.log(res);
        });
    }

    getShopData(shopName) {
        const { shops } = this.props;
        const shop = shops.find((s) => s.name === shopName);
        return shop;
    }

    selectMonth(value) {
        this.setState({ selectedDate: value }, () => {
            const year = dayjs(value).format("YYYY");
            const month = dayjs(value).format("MM");
            this.props.getCurrentStorages(year, month);
        });
    }

    getCSV(shopId, shopName) {
        const year = dayjs(this.state.selectedDate).format("YYYY");
        const month = dayjs(this.state.selectedDate).format("MM");

        this.setState({ creatingCSV: true });

        this.props
            .getCSV(shopId, year, month)
            .then((res) => {
                // downloadBlob(
                //     res.payload.csv,
                //     `${shopName}-${dayjs(this.state.selectedDate).format("MMMM")}-${year}.csv`,
                //     "text/csv;charset=utf-8;"
                // );
                downloadXlsxBlob(res.payload.binaryFile, `${shopName}-${dayjs(this.state.selectedDate).format("MMMM")}-${year}.xlsx`);
                this.setState({ creatingCSV: false });
            })
            .catch(() => {
                this.setState({ creatingCSV: false });
            });
    }

    getXLSX() {
        const year = dayjs(this.state.selectedDate).format("YYYY");
        const month = dayjs(this.state.selectedDate).format("MM");

        this.setState({ creatingCSV: true });

        this.props
            .getXLSXForAllShops(year, month)
            .then((res) => {
                downloadXlsxBlob(res.payload.binaryFile, `Lagerkosten-${dayjs(this.state.selectedDate).format("MMMM")}-${year}.xlsx`);
                this.setState({ creatingCSV: false });
            })
            .catch(() => {
                this.setState({ creatingCSV: false });
            });
    }

    render() {
        const monthCount = [1, 2, 3, 4, 5, 6];
        const { currentStorages } = this.props;
        const { selectedDate, creatingCSV } = this.state;

        return (
            <Container fluid className="main-content-container px-4">
                {/* Page Header */}
                <Row noGutters className="page-header py-4">
                    <PageTitle sm="4" title="Übersicht" subtitle="Lagerkosten" className="text-sm-left" />
                </Row>

                <Row noGutters className="page-header py-4">
                    {monthCount.map((index) => {
                        return (
                            <Button
                                active={
                                    `${dayjs()
                                        .subtract(monthCount.length - index, "month")
                                        .format("YYYY-MM")}` === selectedDate
                                }
                                outline
                                pill
                                theme="primary"
                                key={index}
                                className="mr-2"
                                onClick={() =>
                                    this.selectMonth(
                                        `${dayjs()
                                            .subtract(6 - index, "month")
                                            .format("YYYY-MM")}`
                                    )
                                }
                            >
                                {dayjs()
                                    .subtract(6 - index, "month")
                                    .format("MMMM YYYY")}
                            </Button>
                        );
                    })}
                </Row>

                <Row noGutters className="page-header py-4">
                    <Button theme="primary" onClick={this.getXLSX}>
                        Download XLSX-Monatstabelle
                    </Button>
                </Row>

                <Row>
                    {currentStorages.map((shopStorage, idx) => {
                        const shopData = this.getShopData(shopStorage.shopName);
                        return (
                            <Col lg="3" className="mb-4" key={idx}>
                                <Card small className="mb-4 pt-3">
                                    <CardHeader className="border-bottom text-center">
                                        <div
                                            className="mb-3 mx-auto d-flex align-items-center justify-content-center"
                                            style={{ height: "50px" }}
                                        >
                                            <img
                                                className=""
                                                src={shopData && shopData.logo}
                                                alt="userDetails.username"
                                                style={{ maxHeight: "50px", maxWidth: "110px" }}
                                            />
                                        </div>
                                        <h4 className="mb-0">{shopStorage.shopName}</h4>
                                        <span className="text-muted d-block mb-2">{shopData.representative}</span>
                                        <Link to={`${this.props.match.url}/${shopData._id}`}>
                                            <Button pill outline size="sm" className="mb-2">
                                                <i className="material-icons mr-1">list</i> detailierte Ansicht
                                            </Button>
                                        </Link>
                                        {/* <div>
                                            <Button size="sm" className="mb-2" onClick={() => this.getCSV(shopStorage.shopId)}>
                                                Download CSV
                                            </Button>
                                        </div> */}
                                    </CardHeader>
                                    <ListGroup flush>
                                        {/* <ListGroupItem className="px-4">
                                        <div className="progress-wrapper">
                                            <strong className="text-muted d-block mb-2">"userDetails.performanceReportTitle"</strong>
                                            <Progress className="progress-sm" value="userDetails.performanceReportValue">
                                            <span className="progress-value">"userDetails.performanceReportValue"%</span>
                                        </Progress>
                                        </div>
                                    </ListGroupItem> */}
                                        {/* <ListGroupItem className="p-4">
                                            <strong className="text-muted d-block mb-2">Produkte</strong>
                                            <span>
                                                {shopStorage.products.reduce((acc, p) => (acc += parseInt(p.count)), 0)} Stück aus{" "}
                                                {shopStorage.products.length} Produkten
                                            </span>
                                        </ListGroupItem>
                                        <ListGroupItem className="pl-4 pt-0 pr-4 pb-4">
                                            <strong className="text-muted d-block mb-2">Gesamt-Kosten</strong>
                                            <span>
                                                {Math.round(
                                                    shopStorage.products.reduce((acc, p) => {
                                                        if (shopStorage.shopName === "0816") {
                                                            console.log(parseFloat(p.cost) * parseInt(p.count));
                                                        }
                                                        return (acc += parseFloat(p.cost) * parseInt(p.count));
                                                    }, 0) * 100
                                                ) / 100}
                                                €
                                            </span>
                                        </ListGroupItem> */}
                                        <ListGroupItem className="pl-4 pr-4 pb-4 pt-4">
                                            <div className="d-flex justify-content-center">
                                                <Button
                                                    size="sm"
                                                    className="mb-2"
                                                    onClick={() => this.getCSV(shopStorage._id, shopStorage.shopName)}
                                                    disabled={creatingCSV}
                                                >
                                                    Download CSV
                                                </Button>
                                            </div>
                                        </ListGroupItem>
                                    </ListGroup>
                                </Card>
                            </Col>
                        );
                    })}
                </Row>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        currentStorages: state.statsReducer.currentStorages,
        shops: state.statsReducer.shops,
    };
};

export default connect(mapStateToProps, { getCurrentStorages, getCSV, getAllShops, getXLSXForAllShops })(StorageCosts);
