export default function() {
    return [
        {
            title: "Dashboard",
            htmlBefore: '<i class="material-icons">dashboard</i>',
            to: "/dashboard",
        },
        {
            title: "Lagerkosten",
            htmlBefore: '<i class="material-icons">money</i>',
            to: "/storage-costs",
        },
        // {
        //   title: 'Benutzer',
        //   htmlBefore: '<i class="material-icons">person</i>',
        //   to: '/users',
        // },
    ];
}
