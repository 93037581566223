import React from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Card, CardHeader, CardBody } from "shards-react";
import PageTitle from "../components/common/PageTitle";
import RangeDatePicker from "../components/common/RangeDatePicker";

import moment from "moment";
import "moment/locale/de";

import { getStoragesByShopId, getAllShops } from "../redux/StatsActions";

class StorageCostsDetails extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedDate: new Date(),
            selectedStorage: null,
        };

        this.findStorages = this.findStorages.bind(this);

        moment.locale("de");
    }

    componentDidMount() {
        this.props.getAllShops();
        this.props.getStoragesByShopId(this.props.match.params.shopId).then(() => {
            this.findStorages();
        });
    }

    findStorages() {
        const storages = this.props.storagesByShopId[this.props.match.params.shopId] || [];
        const startOfDay = moment(this.state.selectedDate)
            .clone()
            .startOf("day")
            .format();
        const endOfDay = moment(this.state.selectedDate)
            .clone()
            .endOf("day")
            .format();

        const st = storages.find((s) => {
            return moment(s.createdAt).format() >= startOfDay && moment(s.createdAt).format() <= endOfDay;
        });

        this.setState({
            selectedStorage: st || null,
        });
    }

    render() {
        const { shops, match } = this.props;
        const { selectedDate, selectedStorage } = this.state;

        return (
            <Container fluid className="main-content-container px-4">
                {/* Page Header */}
                <Row noGutters className="page-header py-4">
                    <PageTitle
                        sm="4"
                        title={
                            match.params.shopId && shops.length > 0 ? shops.find((s) => s._id.toString() === match.params.shopId).name : ""
                        }
                        subtitle="Lagerkosten"
                        className="text-sm-left"
                    />
                </Row>

                <Row>
                    <Col className="mb-4">
                        <RangeDatePicker
                            date={selectedDate}
                            onChange={(date) => {
                                this.setState(
                                    {
                                        selectedDate: date,
                                    },
                                    function() {
                                        this.findStorages();
                                    }
                                );
                            }}
                        />
                    </Col>
                </Row>

                {/* Default Light Table */}
                <Row>
                    <Col>
                        <Card small className="mb-4">
                            <CardHeader className="border-bottom">
                                <h6 className="m-0">{moment(selectedDate).format("dddd, Do MMMM YYYY")}</h6>
                            </CardHeader>
                            <CardBody className="p-0 pb-3">
                                <table className="table mb-0">
                                    <thead className="bg-light">
                                        <tr>
                                            <th scope="col" className="border-0">
                                                #
                                            </th>
                                            <th scope="col" className="border-0">
                                                Produkt-Id
                                            </th>
                                            <th scope="col" className="border-0">
                                                Produkt
                                            </th>
                                            <th scope="col" className="border-0">
                                                Anzahl
                                            </th>
                                            <th scope="col" className="border-0">
                                                Kosten pro Produkt
                                            </th>
                                            <th scope="col" className="border-0">
                                                Gesamtkosten
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {selectedStorage &&
                                            selectedStorage.products
                                                .sort((a, b) => {
                                                    if (parseInt(a.count) > parseInt(b.count)) return -1;
                                                    else if (parseInt(a.count) < parseInt(b.count)) return 1;
                                                    else return 0;
                                                })
                                                .map((product, idx) => (
                                                    <tr key={idx}>
                                                        <td>{idx + 1}</td>
                                                        <td>{product.externalId}</td>
                                                        <td>{product.name}</td>
                                                        <td>{product.count}</td>
                                                        <td>{product.cost}€</td>
                                                        <td>
                                                            {Math.round(parseFloat(product.cost) * parseInt(product.count) * 100) / 100}€
                                                        </td>
                                                    </tr>
                                                ))}
                                    </tbody>
                                </table>
                                {!selectedStorage && <div className="d-flex justify-content-center py-5">Daten werden geladen...</div>}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        storagesByShopId: state.statsReducer.storagesByShopId,
        shops: state.statsReducer.shops,
    };
};

const mapActionsToProps = {
    getStoragesByShopId,
    getAllShops,
};

export default connect(mapStateToProps, mapActionsToProps)(StorageCostsDetails);
