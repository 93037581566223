import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Provider } from "react-redux";
import { withCookies } from "react-cookie";
import { LOGIN, RESTORE_DEVICE } from "./redux/AuthActions";

import routes from "./routes";
import withTracker from "./withTracker";

import store from "./utils/store";

import "bootstrap/dist/css/bootstrap.min.css";
import "./shards-dashboard/styles/shards-dashboards.1.1.0.min.css";
import "./shards-dashboard/styles/custom.css";

class App extends React.Component {
    constructor(props) {
        super(props);
        const { cookies } = props;

        // get user cookies and init in redux store
        store.dispatch({
            type: LOGIN,
            payload: {
                user: cookies.get("user") || null,
                token: cookies.get("token") || null,
            },
        });

        // get user cookies and init in redux store
        store.dispatch({
            type: RESTORE_DEVICE,
            payload: {
                device: cookies.get("device") || null,
                deviceName: cookies.get("deviceName") || null,
                printerUrl: cookies.get("printerUrl") || null,
            },
        });
    }

    render() {
        return (
            <Provider store={store}>
                <Router basename={process.env.REACT_APP_BASENAME || ""}>
                    <div>
                        {routes.map((route, index) => {
                            return (
                                <Route
                                    key={index}
                                    path={route.path}
                                    exact={route.exact}
                                    component={withTracker((props) => {
                                        return (
                                            <route.layout {...props}>
                                                <route.component {...props} routes={route.routes} />
                                            </route.layout>
                                        );
                                    })}
                                />
                            );
                        })}
                    </div>
                </Router>
            </Provider>
        );
    }
}

export default withCookies(App);
